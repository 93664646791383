import {
    Component,
    ComponentFactoryResolver,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewContainerRef,
} from "@angular/core";
import { Table } from "./Table";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-table-cell")
@Component({
    selector: "s25-ng-table-cell",
    template: `
        @if (overrides?.if !== false && (overrides?.if === true || defaults?.if !== false)) {
            @if (overrides?.text ?? defaults?.text != null) {
                <span>{{ overrides?.text ?? defaults?.text }}</span>
            }
        }
        <!-- Component cells are inserted AFTER the actual table cell component -->
    `,
})
export class S25TableCellComponent implements OnInit, OnDestroy {
    @Input() column: Table.Column;
    @Input() defaults: Table.Cell;
    @Input() overrides: Table.Cell;
    @Input() row: Table.Row;

    text: string | number; // Text if no component
    eventListeners: (() => void)[] = []; // Event listeners on component

    constructor(
        private elementRef: ElementRef,
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private renderer: Renderer2,
    ) {}

    ngOnInit() {
        const config = this.getConfig();
        this.renderCell(config);
    }

    getConfig() {
        // If both are defined, combine
        if (this.overrides && this.defaults) return { ...this.defaults, ...this.overrides };
        // Return whichever is defined
        return this.overrides ?? this.defaults;
    }

    renderCell(config: Table.Cell) {
        if (!config) return; // Display nothing if no config
        const { text, component, inputs, outputs, events } = config;
        if (text) this.text = text; // Display text
        // Skip if no component was passed or the cell is hidden
        if (!component || config.if === false) return;
        this.viewContainerRef.clear();
        // Get factory and create component
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        const componentRef = this.viewContainerRef.createComponent(componentFactory);
        const instance = componentRef.instance;
        if (this.row && this.column) {
            this.row.cells[this.column.id] ??= { ...this.column.content }; // Just needs to be a shallow copy
            this.row.cells[this.column.id].instance = instance;
        }
        // Apply inputs, outputs, and event listeners
        for (let [input, value] of Object.entries(inputs ?? {})) instance[input] = value;
        for (let [output, handler] of Object.entries(outputs ?? {}))
            instance[output].subscribe((event: any) => handler(event, this.row, instance));
        for (let [eventType, handler] of Object.entries(events ?? {})) {
            const stop = this.renderer.listen(componentRef.location.nativeElement, eventType, (event: any) =>
                handler(event, this.row, instance),
            );
            this.eventListeners.push(stop);
        }
    }

    ngOnDestroy() {
        for (let stopListening of this.eventListeners) stopListening();
    }
}
