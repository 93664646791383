import {
    ChangeDetectionStrategy,
    Component,
    effect,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    Signal,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25LoadingApi } from "../../s25-loading/loading.api";

@TypeManagerDecorator("generic-table-simple-select")
@Component({
    selector: "generic-table-simple-select",
    template: `
        <s25-loading-inline [model]="{}"></s25-loading-inline>
        <div class="select-wrapper" [class.has-error]="errorMsg?.()">
            @if (!isLoading?.()) {
                <select
                    [(ngModel)]="modelValue"
                    (ngModelChange)="modelValueChange.emit(modelValue)"
                    class="cn-form__control"
                >
                    @for (option of options; track $index) {
                        <option [value]="option.value" [disabled]="!!option.disabled">{{ option.label }}</option>
                    }
                </select>
                @if (errorMsg?.()) {
                    <p class="error-message">{{ errorMsg() }}</p>
                }
            }
        </div>
    `,
    styles: `
        ::ng-deep .nm-party--on .error-message {
            color: #ff837b;
        }

        ::ng-deep body#s25.nm-party--on .has-error select.cn-form__control,
        body.nm-party--on .has-error select.cn-form__control,
        html.nm-party--on .has-error select.cn-form__control {
            border-bottom-color: #ff837b !important;
        }

        .select-wrapper {
            display: flex;
            flex-direction: column;
            width: fit-content;
        }

        .has-error {
            position: relative;
            top: 11px;
            margin-bottom: 5px;
        }

        .has-error select {
            border-bottom: 2px solid red !important;
        }

        .error-message {
            color: red;
            font-size: 0.8rem;
            text-wrap: nowrap;
        }

        s25-loading-inline {
            margin: 0;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericTableSimpleSelectComponent {
    @Input() modelValue: string | number;
    @Input() options: { value: string | number; label: string; disabled?: boolean }[];
    @Input() errorMsg: Signal<string>;
    @Input() isLoading: Signal<boolean>;
    @Output() modelValueChange = new EventEmitter<string | number>();

    constructor(private elementRef: ElementRef) {
        effect(
            () => {
                if (this.isLoading?.()) {
                    S25LoadingApi.init(this.elementRef.nativeElement);
                } else {
                    S25LoadingApi.destroy(this.elementRef.nativeElement);
                }
            },
            { allowSignalWrites: true },
        );
    }
}
