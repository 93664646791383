import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewRef,
} from "@angular/core";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Bind } from "../../decorators/bind.decorator";
import { Debounce } from "../../decorators/debounce.decorator";
import { Table } from "./Table";
import { MultiselectModelI } from "../s25-multiselect/s25.multiselect.component";
import { UserprefService } from "../../services/userpref.service";
import { PreferenceService } from "../../services/preference.service";
import { SortOrderService } from "../../services/sort.order.service";
import { S25InfiniteScrollDirective } from "../s25-infinite-scroll/s25.infinite.scroll.directive";
import { S25TableUtil } from "./s25.table.util";
import { Memo } from "../../decorators/memo.decorator";

@TypeManagerDecorator("s25-ng-table")
@Component({
    selector: "s25-ng-table",
    template: `
        <div
            class="s25ngTable"
            [class.pivoted]="isPivoted"
            [class.unlimitedWidth]="unlimitedWidth"
            [style.--table-column-width-min.px]="minColWidth"
            [style.--table-column-width.px]="colWidth"
            [style.--table-column-width-max.px]="maxColWidth"
        >
            <s25-loading-inline class="loadingSpinner" [model]="{}"></s25-loading-inline>
            @if (isInit && (!!rows.length || showHeaderWhenNoData)) {
                <div class="rose-object-table-header">
                    <div class="left">
                        <ng-container *ngTemplateOutlet="tableHeader"></ng-container>
                        @if (hasFilter) {
                            <label class="filter">
                                Filter:
                                <input
                                    class="c-input"
                                    type="text"
                                    [(ngModel)]="filterValue"
                                    (ngModelChange)="applyFilterDebounced($event)"
                                />
                            </label>
                        }
                    </div>
                    <div class="right">
                        @if (hasColumnChooser) {
                            <s25-ng-multiselect-popup
                                [modelBean]="columnSelectBean"
                                [popoverTemplate]="searchCriteriaMultiselect"
                            ></s25-ng-multiselect-popup>
                        }
                        <ng-template #searchCriteriaMultiselect>
                            <s25-ng-multiselect [modelBean]="columnSelectBean"></s25-ng-multiselect>
                        </ng-template>
                        @if (rowSortable && !isPaginated) {
                            <button
                                class="aw-button aw-button--primary ngFloatRight"
                                [disabled]="!!filterValue"
                                (click)="saveSortOrder()"
                            >
                                Save Sort
                            </button>
                        }
                        @if (hasRefresh) {
                            <span
                                (click)="refresh(true)"
                                (keydown.enter)="refresh(true)"
                                aria-label="Refresh"
                                role="button"
                                tabindex="0"
                                class="btn btn-flat btn-icon refreshButton"
                            >
                                <svg class="c-svgIcon" role="img">
                                    <title>Refresh</title>
                                    <use
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#refresh"
                                    ></use>
                                </svg>
                            </span>
                        }
                    </div>
                </div>
            }
            @if (isInit && !filteredRows.length) {
                <div class="noResults">{{ noResultsMessage }}</div>
            }
            @if (isInit && !!filteredRows.length) {
                <div>
                    @if (hasTotalRowCount || hasSelect) {
                        <div class="totalCount">
                            <span>{{ getRowCount() }}</span>
                        </div>
                    }
                    <div
                        #infiniteScrollElement
                        class="tableWrapper"
                        [class.infiniteScroll]="dataSource.type === 'infinite scroll'"
                        s25-infinite-scroll
                        [onScroll]="infiniteScroll"
                        [hasMorePages]="hasMorePages"
                        [topSelector]="'self'"
                        [scrollThreshold]="0"
                    >
                        <table class="s25-ng-table--table" [attr.aria-label]="caption">
                            <caption class="visuallyHidden">
                                {{
                                    caption
                                }}
                            </caption>
                            @if (!isPivoted) {
                                <thead class="tableHeader">
                                    <tr>
                                        @if (rowSortable && !isPaginated) {
                                            <th
                                                data-label="Drag"
                                                class="s25TableColumn s25-ng-table--header"
                                                [style.--column-width]="0"
                                            ></th>
                                        }
                                        @if (rowSortable && !isPaginated) {
                                            <th
                                                scope="col"
                                                tabindex="0"
                                                data-label="Order"
                                                class="s25TableColumn s25-ng-table--header"
                                                [attr.col]="'sort'"
                                                [style.--column-width.px]="0"
                                            >
                                                <span class="headerText">Order</span>
                                            </th>
                                        }
                                        @if (hasSelect) {
                                            <th
                                                scope="col"
                                                tabindex="0"
                                                data-label="Select"
                                                class="s25TableColumn s25-ng-table--header"
                                                [attr.col]="'select'"
                                                [style.--column-width]="0"
                                                [style.text-align]="'center'"
                                            >
                                                @if (hasSelectAll) {
                                                    <s25-ng-checkbox
                                                        [(modelValue)]="selectAll"
                                                        (modelValueChange)="onSelectAll($event)"
                                                        [ariaLabel]="'Select All'"
                                                        [noLabel]="true"
                                                    ></s25-ng-checkbox>
                                                }
                                            </th>
                                        }
                                        @for (column of visibleColumns; track column.id) {
                                            <th
                                                (click)="sortByColumn(column)"
                                                (keydown.enter)="sortByColumn(column)"
                                                class="s25TableColumn b-listview-th ngTableCell s25-ng-table--header"
                                                [attr.col]="column.id"
                                                [class.ngCpointer]="columnSortable && column?.sortable !== false"
                                                tabindex="0"
                                                [style.text-align]="column.align || align"
                                                [style.--column-width.px]="column.width === 'min-content' ? 0 : null"
                                                [class.sortable]="columnSortable && column?.sortable !== false"
                                            >
                                                @if (columnSortable && column?.sortable !== false) {
                                                    <s25-ng-sort-indicator
                                                        [order]="
                                                            column.id !== sortedColumnId ? 'default' : sortedColumnOrder
                                                        "
                                                    ></s25-ng-sort-indicator>
                                                }
                                                <span class="headerText">{{ column.header }}</span>
                                            </th>
                                        }
                                    </tr>
                                </thead>
                            }
                            <tbody
                                s25-ng-dnd-sortable
                                [items]="filteredRows"
                                [sortable]="rowSortable && !isPaginated && !filterValue"
                            >
                                @for (row of filteredRows; track row.id; let i = $index) {
                                    <tr
                                        tabindex="{{ rowSortable && !isPaginated && !filterValue ? 0 : -1 }}"
                                        s25-ng-dnd-sortable-item
                                        [draggable]="rowSortable && !isPaginated && !filterValue"
                                        [index]="i"
                                    >
                                        @if (isPivoted) {
                                            <th class="s25-ng-table--header" [attr.col]="'pivot'">
                                                {{ row.name }}
                                            </th>
                                        }
                                        @if (rowSortable && !isPaginated) {
                                            <td class="s25-ng-table--cell" data-label="Drag">
                                                <s25-ng-icon [type]="'dragHandle'"></s25-ng-icon>
                                            </td>
                                        }
                                        @if (rowSortable && !isPaginated) {
                                            <td
                                                data-label="Order"
                                                class="s25-ng-table--cell"
                                                [attr.col]="'sort'"
                                                [style.--column-width.px]="50"
                                            >
                                                <input
                                                    #orderInput
                                                    type="number"
                                                    min="1"
                                                    max="{{ filteredRows.length }}"
                                                    class="c-input noArrows"
                                                    [ngModel]="i + 1"
                                                    (ngModelChange)="onReorder(orderInput, i, $event - 1)"
                                                    [disabled]="!!filterValue"
                                                    [attr.aria-label]="'Row Order'"
                                                />
                                            </td>
                                        }
                                        @if (hasSelect) {
                                            <td
                                                data-label="Select"
                                                class="s25-ng-table--cell"
                                                [attr.col]="'select'"
                                                [style.text-align]="'center'"
                                            >
                                                @if (row.selectable !== false) {
                                                    <s25-ng-checkbox
                                                        s25-ng-shift-selectable
                                                        [shiftSelectIndex]="i"
                                                        [shiftSelectGroup]="'S25TableSelectRow' + tableNumber"
                                                        [modelValue]="selected.has(row.id)"
                                                        (modelValueChange)="onSelectRow(row.id, $event)"
                                                        [ariaLabel]="'Select Row'"
                                                        [noLabel]="true"
                                                    ></s25-ng-checkbox>
                                                }
                                            </td>
                                        }
                                        @for (column of visibleColumns; track column.id) {
                                            <td
                                                [attr.data-label]="column.header"
                                                [style.text-align]="column.align || align"
                                                class="s25-ng-table--cell {{
                                                    row.cells[column.id]?.className || column.content?.className
                                                }}"
                                                [attr.col]="column.id"
                                                [style.--column-width-min.px]="
                                                    column.width !== 'min-content' ? column.minWidth : 0
                                                "
                                                [style.--column-width.px]="
                                                    column.width !== 'min-content' ? column.width : null
                                                "
                                                [style.--column-width-max.px]="column.maxWidth"
                                            >
                                                <s25-ng-table-cell
                                                    [column]="column"
                                                    [defaults]="column.content"
                                                    [overrides]="row.cells[column.id]"
                                                    [row]="row"
                                                ></s25-ng-table-cell>
                                            </td>
                                        }
                                    </tr>
                                }
                            </tbody>
                        </table>
                        @if (isInit && dataSource.type === "infinite scroll") {
                            <s25-loading-inline class="loadingRows" [model]="{}"></s25-loading-inline>
                        }
                    </div>
                    @if (dataSource.type === "paginated") {
                        <div class="paginator">
                            <div class="pageSelection">
                                <button
                                    class="prev"
                                    [attr.aria-label]="'Previous page'"
                                    [disabled]="currentPage === 0"
                                    (click)="goToPage(currentPage - 1)"
                                    (keydown.enter)="goToPage(currentPage - 1)"
                                >
                                    <svg class="c-svgIcon" role="img">
                                        <title>Previous page</title>
                                        <use
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#caret--caret-left"
                                        ></use>
                                    </svg>
                                </button>
                                <div class="pages">
                                    @for (page of pageList; track $index) {
                                        <button
                                            [attr.aria-label]="'Page ' + page"
                                            [class.dots]="page === '...'"
                                            [class.current]="page === currentPage + 1"
                                            [disabled]="page === '...'"
                                            (click)="goToPage(page - 1)"
                                            (keydown.enter)="goToPage(page - 1)"
                                        >
                                            {{ page }}
                                        </button>
                                    }
                                </div>
                                <button
                                    class="next"
                                    [attr.aria-label]="'Next page'"
                                    [disabled]="currentPage == totalPages - 1"
                                    (click)="goToPage(currentPage + 1)"
                                    (keydown.enter)="goToPage(currentPage + 1)"
                                >
                                    <svg class="c-svgIcon" role="img">
                                        <title>Next page</title>
                                        <use
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#caret--caret-right"
                                        ></use>
                                    </svg>
                                </button>
                            </div>
                            <label class="pageSize">
                                Page Size:
                                <select
                                    [ngModel]="pageSize"
                                    (ngModelChange)="setPageSize($event)"
                                    class="cn-form__control"
                                >
                                    @for (size of dataSource.pageSizes || [25, 50, 100]; track size) {
                                        <option [ngValue]="size">
                                            {{ size }}
                                        </option>
                                    }
                                </select>
                            </label>
                        </div>
                    }
                    @if (rowSortable && !isPaginated) {
                        <button
                            class="aw-button aw-button--primary c-margin-top--single c-margin-bottom--double"
                            [disabled]="!!filterValue"
                            (click)="saveSortOrder()"
                        >
                            Save Sort
                        </button>
                    }
                </div>
            }
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25TableComponent implements OnInit {
    // Required
    @Input() dataSource: Table.DataSource;
    // Options
    @Input() rowSortable? = false; // Incompatible with pagination
    @Input() columnSortable? = false; // Allow user to click headers to sort by column
    @Input() hasColumnChooser? = false; // Allow user to choose which columns are visible
    @Input() hasRefresh? = false; // Button for refreshing table
    @Input() hasFilter? = false; // Text input for filtering data. For paginated services the user input is passed to the data service
    @Input() hasSelect? = false; // Allow users to select rows
    @Input() hasSelectAll? = true; // Allow users to select all rows at once
    @Input() hasTotalRowCount? = false; // Displays visible and total number of rows
    @Input() caption?: string = "Data Table"; // Title
    @Input() sortOrderPreference?: string; // Used solely for saving row order
    @Input() stickyColumnPreference?: string; // Used solely for saving visible/hidden columns
    @Input() stickyColumnSortPreference?: string; // Used solely for saving sorting
    @Input() pivotThreshold?: number = 500; // Minimum width of a table before it pivots
    @Input() pivotThresholdColumn?: number | null = 50; // Minimum width of columns before the table pivots
    @Input() align?: Table.Column["align"] = "left"; // Default CSS "text-align" value for cells
    @Input() selected? = new Set<Table.Row["id"]>(); // Currently selected row ids
    @Input() selectAll? = false; // Whether select-all is toggled
    @Input() noResultsMessage?: string = "No Results"; // Message to be shown if there are no results to show
    @Input() showHeaderWhenNoData?: boolean = false; // Whether to show the header when we do not have any data to display
    @Input() unlimitedWidth?: boolean = false; // Whether max-width should be applied
    @Input() minColWidth?: number; // Minimum column width in pixels
    @Input() colWidth?: number; // Default column width in pixels
    @Input() maxColWidth?: number; // Maximum column width in pixels

    @Output() selectedChange = new EventEmitter<Set<Table.Row["id"]>>(); // Emits whenever a user selects or deselects a row. This includes using select-all
    @Output() selectAllChange = new EventEmitter<boolean>(); // Emits whenever a user hits select-all. When unpaginated, this also emits when all items have been selected
    @Output() onRefresh = new EventEmitter<void>(); // Emits when the user clicks the refresh button

    @ViewChild(S25InfiniteScrollDirective) infiniteScrollDirective: S25InfiniteScrollDirective;
    @ViewChild("infiniteScrollElement") infiniteScrollElement: ElementRef;
    @ContentChild("tableHeader") tableHeader: TemplateRef<any>;

    isInit = false;
    isPivoted: boolean;
    filterValue: string = "";
    rows: Table.Row[] = [];
    filteredRows: Table.Row[];
    totalRows: number;
    visibleColumns: Table.Column[];
    columnSelectBean: MultiselectModelI;
    sortedColumnId: number | string = "";
    sortedColumnOrder: "asc" | "desc" = "desc";

    isPaginated: boolean; // Either pages or infinite scroll
    currentPage: number = 0; // Used with both pages and infinite scrolls
    pageSize: number; // Only used with pages
    pageList: (string | number)[] = [1]; // Only used with pages
    totalPages = 1; // Only used with pages
    cacheId: string | number;

    tableNumber: number;
    private static count = 0;

    windowSize = { width: window.innerWidth, height: window.innerHeight };

    constructor(
        private elementRef: ElementRef,
        private changeDetector: ChangeDetectorRef,
    ) {
        this.tableNumber = S25TableComponent.count;
        S25TableComponent.count++;
    }

    async ngOnInit() {
        this.visibleColumns = this.dataSource.columns;
        if (this.dataSource.type !== "unpaginated") {
            this.isPaginated = true;
        }
        if (this.dataSource.type === "paginated") {
            this.pageSize = this.dataSource.defaultPageSize ?? 25;
            this.dataSource.pagesShown = this.dataSource.pagesShown ?? 7;
            this.currentPage = this.dataSource.startPage ?? 0;
        }
        this.initColumnChooser();
        this.isPivoted = this.shouldPivot(this.elementRef.nativeElement);

        if (this.stickyColumnSortPreference) {
            const colKey = `sticky_sort_col_${this.stickyColumnSortPreference}`;
            const dirKey = `sticky_sort_col_dir_${this.stickyColumnSortPreference}`;
            const stickyColumnSortPrefs = await PreferenceService.getGenericPreference("list", [colKey, dirKey]);
            this.sortedColumnOrder = stickyColumnSortPrefs[dirKey];
            if (this.sortedColumnOrder) this.sortedColumnId = stickyColumnSortPrefs[colKey];
        }

        await this.refresh(false, false, true);
    }

    // Toggle the loading spinner on and off
    @Bind
    displayLoading(on: boolean) {
        if (on) S25LoadingApi.init(this.elementRef.nativeElement);
        else S25LoadingApi.destroy(this.elementRef.nativeElement);
    }

    // Refresh data
    @Bind
    async refresh(forceRefresh = false, resetPagination = false, forInit = false) {
        this.cacheId = resetPagination || forceRefresh ? undefined : this.cacheId;
        this.isInit = false;
        this.displayLoading(true);
        if (!forInit) this.selected.clear();

        if (resetPagination) this.currentPage = 0;
        const queryData: any = {
            page: this.currentPage,
            filterValue: this.filterValue,
            sortColumn: { id: this.sortedColumnId, order: this.sortedColumnOrder },
            forceRefresh: forceRefresh,
            cacheId: this.cacheId,
        };
        if (this.dataSource.type === "paginated") queryData.pageSize = this.pageSize;

        const response = await this.dataSource.dataSource(queryData).catch(this.error);
        if (!response) return;
        const newRows = response.rows ?? [];
        this.totalRows = response.totalRows ?? newRows.length;
        this.cacheId = response.cacheId;

        switch (this.dataSource.type) {
            case "unpaginated":
                this.rows = newRows;
                this.applyFilter(this.filterValue);
                break;
            case "paginated":
                this.totalPages = Math.ceil(this.totalRows / this.pageSize);
                this.currentPage = S25Util.clamp(this.currentPage, 0, this.totalPages - 1); // Make sure we're on a valid page
                this.pageList = this.getPageList(this.dataSource.pagesShown ?? 7);
                this.rows = newRows;
                this.setFilteredRows(this.rows.slice());
                break;
            case "infinite scroll":
                this.rows = this.rows.concat(newRows); // When infinite-scrolling keep previous rows
                this.setFilteredRows(this.rows.slice());
                break;
        }
        this.selectAll = this.isAllSelected();

        if (this.dataSource.type === "unpaginated" && this.sortedColumnId) {
            // If already sorted, keep sort on refresh
            const sortedColumn = this.dataSource.columns.find((col) => col.id === this.sortedColumnId);
            this.applySort(sortedColumn);
        }

        this.displayLoading(false);
        this.isInit = true;
        !(this.changeDetector as ViewRef).destroyed && this.changeDetector.detectChanges();

        if (this.dataSource.type === "infinite scroll" && !this.hasScrollBar() && this.hasMorePages()) {
            // Need more data to fill up table
            this.currentPage++;
            await this.refresh();
        }

        this.onRefresh.emit();
        await this.pivotTable();
    }

    hasScrollBar() {
        const elem = this.infiniteScrollElement?.nativeElement;
        if (!elem) return false;
        return elem.scrollHeight > elem.clientHeight;
    }

    @HostListener("window:resize")
    @Debounce(100)
    async onWindowResize() {
        const { innerWidth, innerHeight } = window;
        // Check if we need to pivot the table only if the window changes width. We don't care about the height
        if (this.windowSize.width !== innerWidth) {
            this.windowSize = { width: innerWidth, height: innerHeight };
            await this.pivotTable();
        }
    }

    // Change from table view to list view
    async pivotTable() {
        await S25Util.delay(0);
        this.isPivoted = this.shouldPivot(this.elementRef.nativeElement);

        if (!(this.changeDetector as ViewRef).destroyed) this.changeDetector.detectChanges();
    }

    // Figure out whether the table should be pivoted
    shouldPivot(table: HTMLElement) {
        if (table.offsetWidth === 0) return this.isPivoted; // If table is not visible, do not change anything

        // Table width
        if (table.offsetWidth < this.pivotThreshold) return true;

        // Column widths
        // We can calculate the column pivot width as
        // TABLE_WIDTH = (COLUMN_THRESHOLD - 0.5) * VISIBLE_DYNAMIC_COLUMNS + VISIBLE_FIXED_COLUMN_WIDTH
        // "-0.5" is to get the threshold when the browser starts rounding down rather than up
        // VISIBLE_DYNAMIC_COLUMNS is the number of visible columns which do not have a fixed width
        // VISIBLE_FIXED_COLUMN_WIDTH is the sum of the widths of fixed-width columns
        const visibleDynamic = S25Util.array.count(this.visibleColumns, (col) => +(typeof col.width !== "number"));
        const visibleFixedWidth = S25Util.array.sum(this.visibleColumns, "width");
        const columnPivotThreshold = (this.pivotThresholdColumn - 0.5) * visibleDynamic + visibleFixedWidth;

        if (table.offsetWidth <= columnPivotThreshold) return true;

        return false;
    }

    @Debounce(300)
    applyFilterDebounced(value: string) {
        this.applyFilter(value);
    }

    // Filter rows by input
    applyFilter(value: string) {
        this.filterValue = value;
        if (this.isPaginated) {
            this.currentPage = 0; // Go to first page
            if (this.dataSource.type === "infinite scroll") this.resetInfiniteScroll();
            return this.refresh(true, true); // If paginated filter server side
        }
        if (!this.filterValue) this.setFilteredRows(this.rows);
        else {
            const filterFunc =
                (this.dataSource.type === "unpaginated" && this.dataSource.customFilter) ?? S25TableUtil.filterRows;
            this.setFilteredRows(this.rows.filter((row) => filterFunc(value, row, this.dataSource.columns)));
        }

        !(this.changeDetector as ViewRef).destroyed && this.changeDetector.detectChanges();
    }

    // Save the order
    saveSortOrder() {
        document.documentElement.scrollTop = 0;
        const sortOrder: Table.SortOrder = this.filteredRows.map(({ id }, i) => ({ itemId: id, sortOrder: i + 1 }));
        this.displayLoading(true);
        return SortOrderService.setSortOrder(this.sortOrderPreference, null, sortOrder).then(
            () => this.displayLoading(false),
            this.error,
        );
    }

    // Display an error to the user
    @Bind
    error(error: any) {
        this.displayLoading(false);
        S25Util.showError(error);
    }

    // Add a row to the table
    addRow(row: Table.Row) {
        if (!row) return;
        this.rows.push(row);
        this.setFilteredRows(this.rows);
        if (this.sortedColumnId) {
            const column = this.getColumnById(this.sortedColumnId);
            if (column) this.applySort(column);
        }
        this.changeDetector.detectChanges();
    }

    // Update an existing row with a new definition
    updateRow(row: Table.Row) {
        if (!row) return;
        const existingRow = this.rows.find((r) => r.id === row.id);
        if (!existingRow) return;
        Object.assign(existingRow, row);
        this.changeDetector.detectChanges();
    }

    getColumnById(id: Table.Column["id"]): Table.Column {
        return this.dataSource.columns.find((col) => col.id === id);
    }

    // Remove a specific row from the table
    removeRow(row: Table.Row) {
        if (!row) return;
        const index = this.rows.findIndex((r) => r.id === row.id);
        this.rows.splice(index, 1);
        this.setFilteredRows(this.rows);
        this.changeDetector.detectChanges();
    }

    // When a row is moved by either drag and drop sorting or input sorting, update the array
    reordering: { from: number; to: number; timeout: ReturnType<typeof setTimeout>; reorder: (focus: boolean) => void };
    onReorder(elem: HTMLInputElement, from: number, to: number) {
        from = S25Util.clamp(from, 0, this.filteredRows.length - 1);
        to = S25Util.clamp(to, 0, this.filteredRows.length - 1);
        if (from === to) return;

        clearTimeout(this.reordering?.timeout);

        const reorder = (focus: boolean = true) => {
            // Move item
            const item = this.filteredRows.splice(from, 1)[0];
            this.filteredRows.splice(to, 0, item);
            // Get focus back
            if (focus) {
                setTimeout(() => {
                    elem.focus({ preventScroll: true });
                });
            }

            this.reordering = null;
            this.changeDetector.detectChanges();
        };

        // If another reorder is in the queue, fire it off immediately
        if (this.reordering && this.reordering.from !== from) {
            if (this.reordering.from < from) from--;
            if (this.reordering.to < from) from++;
            if (this.reordering.from < to) to--;
            if (this.reordering.to < to) to++;
            this.reordering.reorder(false);
            setTimeout(() => {
                elem.value = String(to + 1);
            });
        }

        const timeout = setTimeout(reorder, 500);
        this.reordering = { from, to, timeout, reorder };
    }

    // Sorts the rows by the clicked column.
    // Paginated services are sorted server side
    sortByColumn(column: Table.Column) {
        if (!this.columnSortable || !(column?.sortable !== false)) return;
        this.sortedColumnOrder =
            this.sortedColumnId === column.id ? (this.sortedColumnOrder === "asc" ? null : "asc") : "desc";
        this.sortedColumnId = column.id;

        // Update Preferences
        if (this.stickyColumnSortPreference) {
            PreferenceService.setGenericPreference(
                "list",
                `sticky_sort_col_${this.stickyColumnSortPreference}`,
                this.sortedColumnId as string,
            );
            PreferenceService.setGenericPreference(
                "list",
                `sticky_sort_col_dir_${this.stickyColumnSortPreference}`,
                this.sortedColumnOrder,
            );
        }

        // If sort is currently asc, then we need to un-sort
        if (this.sortedColumnOrder === null) {
            this.sortedColumnId = "";
            if (this.isPaginated) {
                this.currentPage = 0;
                if (this.dataSource.type === "infinite scroll") this.resetInfiniteScroll();
                return this.refresh(true, true); // Sorting/filtering done server-side
            } else return this.applyFilter(this.filterValue); // Reapply any existing filter
        }

        if (this.isPaginated) {
            // Sort server-side
            this.currentPage = 0;
            if (this.dataSource.type === "infinite scroll") this.resetInfiniteScroll();
            return this.refresh(true, true);
        }
        // Sort in memory
        if (this.filteredRows === this.rows) this.setFilteredRows(this.rows.slice()); // Avoid sorting this.rows
        this.applySort(column);
        this.changeDetector.detectChanges();
    }

    applySort(column: Table.Column) {
        let comparator = S25TableUtil.sortRows;
        if ("customSort" in this.dataSource && this.dataSource.customSort) comparator = this.dataSource.customSort;
        this.filteredRows.sort((a, b) => comparator(a, b, column, this.sortedColumnOrder));
    }

    // Jumps to a page number in paginated services
    goToPage(page: number) {
        if (this.dataSource.type !== "paginated") return;
        if (page === this.currentPage || page < 0 || page >= this.totalPages) return;
        this.currentPage = page;
        return this.refresh();
    }

    // Get a list representing the pages to be shown in the pagination footer (e.g. [1, ..., 3, 4, 5, ..., 10])
    getPageList(shown: number) {
        if (this.dataSource.type !== "paginated") return;
        if (shown <= 2) throw new Error("Number of pages shown cannot be lower than 3");
        if (this.totalPages <= shown) return new Array(this.totalPages).fill(null).map((_, i) => i + 1);
        // Get array with numbers in neighborhood of current page, then replace first and last entries with first and last page
        // Replace second and second to last with '...' if needed
        const startPage = S25Util.clamp(this.currentPage + 1 - Math.floor(shown / 2), 1, this.totalPages - shown + 1);
        let pages: (number | string)[] = new Array(shown).fill(null).map((_, i) => i + startPage);
        if (shown >= 5 && pages[0] !== 1) pages[1] = "..."; // If we show 4 or fewer pages, then '...' does not help
        if (shown >= 5 && pages[shown - 1] !== this.totalPages) pages[shown - 2] = "...";
        pages[0] = 1;
        pages[shown - 1] = this.totalPages;
        return pages;
    }

    // Sets the page size when user selects a new value from the dropdown
    setPageSize(size?: number) {
        this.currentPage = Math.floor((this.currentPage * this.pageSize) / size); // Recalculate which page we are on
        this.pageSize = size;
        this.refresh();
    }

    // Initialize column chooser data
    initColumnChooser() {
        const orderItem = {
            itemName: "Order",
            itemId: "s25TableOrder",
            checked: true,
            isPermanent: true,
        };
        const items = this.dataSource.columns.map((column) => ({
            itemName: column.header,
            itemId: column.id,
            checked: true,
        }));
        if (this.rowSortable) items.unshift(orderItem);

        this.columnSelectBean = {
            title: "Columns",
            hasFilter: false,
            hasSelectAll: false,
            hasSelectNone: false,
            onChange: this.onChooseColumns,
            items,
            selectedItems: items.filter((item) => item.checked),
        };
    }

    // Update which columns are visible after the user makes a selection
    @Bind
    onChooseColumns() {
        this.visibleColumns = this.dataSource.columns.filter(
            (column) => this.columnSelectBean.selectedItems.find((item) => item.itemId === column.id)?.checked,
        );
        this.changeDetector.detectChanges();

        if (!this.stickyColumnPreference) return;
        // Save to DB
        UserprefService.getLoggedIn().then((isLoggedIn) => {
            if (!isLoggedIn) return;
            const columns = this.columnSelectBean.items.map((item) => ({
                isVisible: item.checked,
                name: item.itemName,
                prefname: item.itemId,
            }));
            PreferenceService.updateSearchColListPref(this.stickyColumnPreference, columns).catch(this.error); // Update DB
        });
    }

    setColumns(columns: Table.Column[]) {
        const previous = new Set(this.dataSource.columns.map((col) => col.id));
        const visibleColumns = new Set(this.visibleColumns.map((col) => col.id));
        this.dataSource.columns = columns;
        this.visibleColumns = columns.filter((column) => visibleColumns.has(column.id) || !previous.has(column.id));
        this.changeDetector.detectChanges();
    }

    setVisibleColumns(visible: Set<string | number>) {
        this.visibleColumns = this.dataSource.columns.filter((column) => visible.has(column.id));
        this.changeDetector.detectChanges();
    }

    @Bind
    infiniteScroll() {
        if (this.dataSource.type !== "infinite scroll") return;
        this.currentPage++;
        return this.refresh();
    }

    @Bind
    hasMorePages() {
        if (this.dataSource.type !== "infinite scroll") return false;
        return this.dataSource.hasMorePages(this.currentPage);
    }

    resetInfiniteScroll() {
        this.rows = [];
        if (this.infiniteScrollDirective) this.infiniteScrollDirective.scrollThresholdPx = 0;
    }

    setFilteredRows(rows: Table.Row[]) {
        this.filteredRows = rows;
        this.selectAll = this.isAllSelected();
    }

    // Check if all currently visible rows are selected
    isAllSelected(): boolean {
        return !this.filteredRows.find((row) => !this.selected.has(row.id));
    }

    onSelectAll(selected: boolean): void {
        // Set selected rows
        if (selected) {
            for (let row of this.filteredRows) {
                if (row.selectable === false) continue; // Skip unselectable rows
                this.selected.add(row.id);
            }
        } else this.selected.clear();
        // Update select all
        this.selectAll = selected;
        // Emit
        this.selectedChange.emit(this.selected);
        this.selectAllChange.emit(this.selectAll);
        this.changeDetector.detectChanges();
    }

    onSelectRow(id: Table.Row["id"], selected: boolean): void {
        // Update selection
        if (selected) this.selected.add(id);
        else this.selected.delete(id);
        // Check if all rows are now selected
        const areAllSelected = this.isAllSelected();
        if (this.selectAll !== areAllSelected) {
            this.selectAll = areAllSelected;
            this.selectAllChange.emit(this.selectAll);
        }
        // Emit
        this.selectedChange.emit(this.selected);
        this.changeDetector.detectChanges();
    }

    getRowCount() {
        const counts = { total: this.totalRows, visible: this.filteredRows.length, selected: this.selected.size };
        return this.getRowCountMemo(counts);
    }

    @Memo()
    getRowCountMemo(counts: Table.RowCounts) {
        if (this.dataSource.customRowCount) return this.dataSource.customRowCount(counts);
        if (this.hasSelect)
            return `${this.selected.size.toLocaleString()} of ${this.totalRows.toLocaleString()} rows selected`;
        return `${this.totalRows.toLocaleString()} Result${this.totalRows === 1 ? "" : "s"}`;
    }

    detectChanges() {
        this.changeDetector.detectChanges();
    }
}
