import { OptimizerBoard } from "./board.upload.service";
import { BoardUtil } from "./s25.board.util";
import { SwarmSchedule } from "./SwarmSchedule";
import { BOARD_CONST } from "./s25.board.const";
import { S25Util } from "../../util/s25-util";
import { EventSummary } from "./s25.event.summary.service";
import { StandoutClassroomService } from "../../services/standout.classroom.service";
import { Proto } from "../../pojo/Proto";
import BoardRoom = SwarmSchedule.BoardRoom;
import DowChar = EventSummary.DowChar;
import BoardEvent = SwarmSchedule.BoardEvent;
import Header = MeetingPatternGrid.Header;
import { BoardService } from "./s25.board.service";
import { Debounce } from "../../decorators/debounce.decorator";
import { GridsService } from "../../services/grids.service";

export class MeetingPatternGridService {
    private static mapRoomToRow(room: BoardRoom, index: number) {
        const hash = BoardUtil.itemRowHash(room);
        const row: MeetingPatternGrid.Row = {
            itemName: room.roomName + (room.roomId === -1 ? "" : ` (${room.maxCapacity})`),
            itemId: room.roomId,
            objRef: room,
            rowIndex: index + 1, // +1 accounts for header
            rowHash: hash,
            class: "ngAnchor",
            onClick: (event: any) => {
                // Keep JS PopupService for now since we'll probably just use S25Item for this in the TS component
                window.angBridge.$injector.get("PopupService").createPopup("s25-swarm-location-popup.tmpl", row, event);
            },
        };

        return row;
    }

    private static reduceDowToCol(headers: Header[], dow: string): Header[] {
        for (let i = 0; i < 24; i++) {
            const start = String(i).padStart(2, "0") + ":00";
            const hash = BoardUtil.itemColumnHash({ dow, startHour: i });
            headers.push({
                header_text: `${dow} ${start}`,
                itemName: `${dow} ${start}`,
                daysOfWeek: dow.split("") as DowChar[],
                dow,
                start: S25Util.date.parseTime(start, true),
                colIndex: i,
                colHash: hash,
            });
        }

        return headers;
    }

    public static getUniqueEvents(events: BoardEvent[]) {
        const unique: BoardEvent[] = [];

        const memo: Record<string, BoardEvent> = {};
        for (let item of events) {
            item.dow = BoardUtil.daysOfWeekMap(item.dow);
            item.origDow = BoardUtil.daysOfWeekMap(item.origDow);

            const hash = BoardUtil.itemHash(item);
            const cachedEvent = memo[hash];

            if (!cachedEvent) {
                memo[hash] = item;
                unique.push(item);
            } else {
                cachedEvent.startTime = BoardUtil.minTimeStr(cachedEvent.startTime, item.startTime);
                cachedEvent.endTime = BoardUtil.maxTimeStr(cachedEvent.endTime, item.endTime);
            }
        }

        return unique;
    }

    public static getItems(chartModel: unknown, events: BoardEvent[]) {
        // Get unique common codes to get row num
        const items: MeetingPatternGrid.Item[] = [];
        const itemMap: Record<string, MeetingPatternGrid.Item> = {};

        for (let event of events) {
            if (!event.room) continue;

            const startTime = S25Util.date.parseTime(event.startTime, true);
            const startHour = startTime.getHours();
            const end = S25Util.date.parseTime(event.endTime, true);
            const daysOfWeek = event.dow.split("").filter(BoardUtil.daysOfWeekFilter);
            const colHash = BoardUtil.colHash(event.dow, startHour);

            const item: MeetingPatternGrid.Item = {
                uuid: BoardUtil.itemHash(event),
                chartModel: chartModel,
                objRef: event,
                eventId: event.eventId,
                groupId: event.groupId,
                newpattern: event.newpattern,
                moved: event.moved,
                profileId: event.profileId,
                profileCode: event.profileCode,
                syntheticProfile: event.syntheticProfile,
                occs: event.occs,
                itemId: event.eventId,
                itemName: event.eventName,
                headCount: event.enrollment,
                roomId: event.room.roomId,
                sourceRowHash: BoardUtil.itemRowHash(event.room),
                sourceColHash: colHash,
                daysOfWeek: daysOfWeek,
                origDow: event.origDow,
                dow: event.dow,
                startHour: startHour,
                endHour: event.endHour,
                start: startTime,
                end: end,
                origStartTime: event.origStartTime,
                startTime: event.startTime,
                endTime: event.endTime,
                ts: event.ts,
                multipleRooms: event.multipleRooms,
                draggable: event.draggable,
                linkedItems: [], // Will be populated below using linkedItemsUUID
                linkedItemsUUID: event.linkedItems?.map(BoardUtil.itemHash) || [],
                canHaveSOC: false,
                hasSOC: false,
                setSOC: (value: boolean) => {
                    return StandoutClassroomService.eventSetSOC(Number(event.eventId), value);
                },
            };

            items.push(item);
            itemMap[item.uuid] = item;
        }

        for (let item of items) {
            item.linkedItems = item.linkedItemsUUID
                .filter((uuid) => itemMap[uuid])
                .map((uuid) => {
                    const link = itemMap[uuid];
                    const sync = link.dow === item.dow && link.roomId === item.roomId;

                    return { objRef: link, sync };
                });
        }

        return items;
    }

    public static getModel(board: { root: OptimizerBoard }) {
        if (!board) return;

        // Form rows
        const rooms = board.root.room;
        rooms.sort(BoardUtil.roomSort);
        const rows = rooms.map(this.mapRoomToRow);

        // Form columns
        const headers = BOARD_CONST.allDows.reduce(this.reduceDowToCol, []);

        // Only have one event per unique event id
        const events = this.getUniqueEvents(board.root.item);

        const model: MeetingPatternGrid.Model = {
            boardId: board.root.boardId,
            boardUUID: board.root.boardUUID,
            boardName: board.root.boardName,
            allowXDrag: board.root.allowXDrag,
            minFillRatio: board.root.minFillRatio,
            shared: board.root.shared,
            owner: board.root.owner,
            sharedUsers: board.root.sharedUsers,
            username: board.root.username,
            isOwner: board.root.isOwner,
            headers,
            rows,
            items: [],
            allDows: BOARD_CONST.allDows,
            lastTimestamp: board.root.last_timestamp || 0,
            origResults: board.root.origResults,
            origResultsMap: board.root.origResultsMap,
            sessionId: board.root.sessionId,
            is_shared: true, // todo: come from WS: board.root.shared !!!
        };

        model.items = this.getItems(model, events);

        return model;
    }

    public static getChartPreferences() {
        return GridsService.getPreferences();
    }

    @Debounce(300)
    public static async dispatch(boardUUID: string, itemTos: SwarmSchedule.EventI[]) {
        const [ok, err] = await S25Util.Maybe(BoardService.dispatch(boardUUID, itemTos));
        if (err) S25Util.showError(err);
    }

    public static async poll(boardUUID: string) {
        const data = await BoardService.subscribe(boardUUID);
        const messages = data?.ItemRequestContainer?.messages || [];
        return messages;
    }

    public static async lockItems(boardUUID: string, itemTos: SwarmSchedule.EventI[]) {
        const [ok, err] = await S25Util.Maybe(
            Promise.all(itemTos.map((itemTo) => BoardService.lockItem(boardUUID, itemTo))),
        );
        if (err) {
            S25Util.errorText(err, "The item could not be locked for editing.");
            MeetingPatternGridService.unlockItems(boardUUID, itemTos);
        }
        return !err;
    }

    public static async unlockItems(boardUUID: string, itemTos: SwarmSchedule.EventI[]): Promise<boolean> {
        const [ok, err] = await S25Util.Maybe(
            Promise.all(itemTos.map((itemTo) => BoardService.unlockItem(boardUUID, itemTo))),
        );
        if (err) S25Util.showError(err);
        return !err;
    }
}

export namespace MeetingPatternGrid {
    import DowChar = EventSummary.DowChar;
    import NumericalString = Proto.NumericalString;
    import ISOTimeString = Proto.ISOTimeString;
    import ISODateString = Proto.ISODateString;
    import EventWSI = SwarmSchedule.EventWSI;

    export type Model = {
        boardId: OptimizerBoard["boardId"];
        boardUUID: OptimizerBoard["boardUUID"];
        boardName: OptimizerBoard["boardName"];
        allowXDrag: OptimizerBoard["allowXDrag"];
        minFillRatio: OptimizerBoard["minFillRatio"];
        shared: OptimizerBoard["shared"];
        owner: OptimizerBoard["owner"];
        sharedUsers: OptimizerBoard["sharedUsers"];
        username: OptimizerBoard["username"];
        isOwner: OptimizerBoard["isOwner"];
        headers: Header[];
        rows: Row[];
        items: Item[];
        allDows: string[];
        lastTimestamp: string | number;
        origResults: OptimizerBoard["origResults"];
        origResultsMap: OptimizerBoard["origResultsMap"];
        sessionId: OptimizerBoard["sessionId"];
        is_shared: boolean;
    };

    export type Row = {
        itemName: string;
        itemId: number;
        objRef: BoardRoom;
        rowIndex: number;
        rowHash: string;
        class: string;
        onClick: (event: any) => void;
    };

    export type Header = {
        header_text: string;
        itemName: string;
        daysOfWeek: DowChar[];
        dow: string;
        start: Date;
        colIndex: number;
        colHash: string;
    };

    export type Item = {
        eventId: NumericalString;
        groupId?: NumericalString;
        moved: boolean;
        newpattern: string;
        profileId: NumericalString;
        syntheticProfile: boolean;
        profileCode: string;
        origDow: string;
        dow: string;
        origStartTime: ISOTimeString;
        startTime: ISOTimeString;
        endTime: ISOTimeString;
        occs: { reservation_start_dt: ISODateString; reservation_end_dt: ISODateString }[];
        linkedItemsUUID: string[];
        linkedItems: { objRef: Item; sync: boolean }[];
        draggable: boolean;
        multipleRooms: boolean;
        roomId?: number;
        uuid?: string;
        startHour?: number;
        endHour?: number;
        ts?: EventWSI["item_timestamp"];
        chartModel?: unknown;
        objRef?: BoardEvent;
        itemId?: BoardEvent["eventId"];
        itemName?: BoardEvent["eventName"];
        headCount?: BoardEvent["enrollment"];
        sourceRowHash?: string;
        sourceColHash?: string;
        daysOfWeek?: string[];
        start?: Date;
        end?: Date;
        canHaveSOC?: boolean;
        hasSOC?: boolean;
        setSOC: (value: boolean) => ReturnType<typeof StandoutClassroomService.eventSetSOC>;
    };
}
