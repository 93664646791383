import { Table } from "./Table";

const comparator = Intl.Collator().compare;

export class S25TableUtil {
    public static emulateInfiniteScroll(config: SimulationConfig): Table.InfiniteScroll {
        let filteredRows: Table.Row[] = config.rows;
        config.pageSize ??= 20;

        // Return pertinent slice of data
        const dataSource = async (query: Table.Query) => {
            // Filter data
            const filterFunc = config.filterFunction || S25TableUtil.filterRows;
            filteredRows = config.rows.slice().filter((row) => filterFunc(query.filterValue, row, config.columns));

            // Sort data
            if (query.sortColumn.id) {
                const sortedColumn = config.columns.find((column) => column.id === query.sortColumn.id);
                const sortFunc = config.sortFunction || S25TableUtil.sortRows;
                filteredRows.sort((a: Table.Row, b: Table.Row) => sortFunc(a, b, sortedColumn, query.sortColumn.order));
            }

            // Get slice of data
            const slice = filteredRows.slice(query.page * config.pageSize, (query.page + 1) * config.pageSize);
            return {
                rows: slice,
                totalRows: filteredRows.length,
                cacheId: query.cacheId,
            };
        };

        // Check whether there is more data to load
        const hasMorePages = (currentPage: number) => filteredRows.length > (currentPage + 1) * config.pageSize;

        return {
            type: "infinite scroll",
            dataSource,
            columns: config.columns,
            hasMorePages,
        };
    }

    public static sortRows(rowA: Table.Row, rowB: Table.Row, column: Table.Column, order: "asc" | "desc") {
        if (order === "asc") [rowA, rowB] = [rowB, rowA]; // Swap if reverse order
        const aVal = S25TableUtil.getSortValue(rowA, column);
        const bVal = S25TableUtil.getSortValue(rowB, column);
        return comparator(aVal, bVal);
    }

    public static getSortValue(row: Table.Row, column: Table.Column): string {
        const cell = row.cells[column.id];
        const col = column.content;
        const sortProp = cell?.sortProp || col?.sortProp;
        const sortInstance = cell?.instance || col?.instance;
        const val =
            cell?.sortValue ??
            col?.sortValue ??
            cell?.text ??
            col?.text ??
            cell?.textValue ??
            col?.textValue ??
            (sortProp ? (sortInstance as any)?.[sortProp] : null) ??
            "";
        return String(val);
    }

    public static filterRows(filterValue: string, row: Table.Row, columns: Table.Column[]): boolean {
        // Filter based on row name
        filterValue = filterValue.toLowerCase();
        if (row.name && row.name.toLowerCase().includes(filterValue)) return true;
        // Filter based on text cells and textValue (for component cells)
        for (let column of columns) {
            const text = S25TableUtil.getFilterValue(row, column);
            if (text.toLowerCase().includes(filterValue)) return true;
        }
    }

    public static getFilterValue(row: Table.Row, column: Table.Column): string {
        const cell = row.cells[column.id];
        const col = column.content;
        const val =
            cell?.filterValue ?? col?.filterValue ?? cell?.text ?? col?.text ?? cell?.textValue ?? col?.textValue ?? "";
        return String(val).toLowerCase();
    }

    // Get a cell's final config based on column defaults and cell values
    public static getConfig(defaults: Table.Cell, overrides: Table.Cell) {
        // If both are defined, combine
        if (overrides && defaults) return { ...defaults, ...overrides };
        // Return whichever is defined
        return overrides ?? defaults;
    }
}

type SimulationConfig = {
    columns: Table.Column[];
    rows: Table.Row[];
    pageSize?: number;
    sortFunction?: (a: Table.Row, b: Table.Row, column: Table.Column, order: "asc" | "desc") => number;
    filterFunction?: (value: string, row: Table.Row, columns: Table.Column[]) => boolean;
};
