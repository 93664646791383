import { DataAccess } from "../../dataaccess/data.access";
import { Proto } from "../../pojo/Proto";
import { Event } from "../../pojo/Event";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";
import { S25Util } from "../../util/s25-util";
import { S25DowPatterns } from "./s25.dow.patterns.const";
import DateFormat = Proto.DateFormat;
import TimeFormat = Proto.TimeFormat;
import ISODateString = Proto.ISODateString;
import ISOTimeString = Proto.ISOTimeString;
import TimeSet = EventSummary.TimeSet;
import DowNumber = Proto.DowNumber;
import { S25Const } from "../../util/s25-const";

export class EventSummaryService {
    public static OCC_LIST_THRESHOLD = 3;
    public static MIN_TIME_RATIO = 0.2;
    public static MIN_EXCEPTION_RATIO = 0.2;

    public static getSummary(eventId: number) {
        return DataAccess.get<{ summary: EventSummary.Summary }>(
            DataAccess.injectCaller(`/event/summary/get.json?event_id=${eventId}`, "EventSummaryDao.getSummary"),
        );
    }

    public static sortRsrv<T extends { startDate: Date; endDate: Date }>(a: T, b: T) {
        if (a.startDate < b.startDate) return -1;
        if (a.startDate > b.startDate) return 1;
        if (a.endDate < b.endDate) return -1;
        if (a.endDate > b.endDate) return 1;
        return 0;
    }

    public static formSummaryDescModel(
        profileCode: string,
        start: Date,
        end: Date,
        timeSet: EventSummary.TimeSet,
        dateFormat: DateFormat,
        timeFormat: TimeFormat,
    ) {
        const startDate = S25Datefilter.transform(start, dateFormat);
        const startTime = S25Datefilter.transform(start, timeFormat);
        const endDate = S25Datefilter.transform(end, dateFormat);
        const endTime = S25Datefilter.transform(end, timeFormat);

        let duration = EventSummaryService.getDurationText(startDate, startTime, endDate, endTime, timeSet);
        const weekdayString = EventSummaryService.getWeekdayText(profileCode, !!timeSet?.resets);
        const repetitionText = EventSummaryService.getRepetitionText(
            profileCode,
            startDate,
            endDate,
            timeSet,
            weekdayString,
        );

        const description: string[] = [];

        // If we have an occurrence happening before the specified start date
        if (timeSet?.[0]?.startDate < start) {
            const startString = EventSummaryService.getStartText(start, end, timeSet, dateFormat, timeFormat);
            description.push(startString);
            duration = "Repeats from " + duration;
        }

        description.push(duration, repetitionText);

        return description;
    }

    public static adhocSummaryModelToProfileCode(timeSet: EventSummary.TimeSet) {
        if (timeSet.pattern?.dowPattern) {
            const pattern = timeSet.pattern.dowPattern;
            const startDate = S25Util.date.toDateTimeNumeric(timeSet[timeSet.length - 1].startDate);
            return `W${pattern.repeatsEvery || 1} ${pattern.dowString} ${startDate}`;
        } else if (timeSet.pattern?.monthPattern) {
            const pattern = timeSet.pattern.monthPattern;
            const days = pattern
                .sort()
                .map((day) => `${day}+ `)
                .join("");
            const startDate = S25Util.date.toDateTimeNumeric(timeSet[timeSet.length - 1].startDate);
            return `MD${pattern.repeatsEvery || 1} ${days} ${startDate}`;
        }
    }

    public static adhocSummaryModel(reservations: Parameters<typeof EventSummaryService._adhocSummaryModel>[0]) {
        return EventSummaryService._adhocSummaryModel(
            reservations,
            EventSummaryService.MIN_TIME_RATIO,
            EventSummaryService.MIN_EXCEPTION_RATIO,
            S25DowPatterns,
        );
    }

    public static _adhocSummaryModel(
        occurrences: EventSummary.Occurrence[],
        minTimeRatio: number,
        minExceptionRatio: number,
        orderedDowPatterns: EventSummary.DowPattern[],
        weeklyOnly: boolean = false,
        skipMerging: boolean = false,
    ) {
        let reservations: EventSummary.TimeSetOccurrence[] = [];

        for (let occ of occurrences) {
            const neverMerge = S25Util.date.diffMinutes(occ.reservation_start_dt, occ.reservation_end_dt) >= 24 * 60;
            const startDate = S25Util.date.parseDropTZ(
                occ.event_start_dt || occ.rsrv_start_dt || occ.reservation_start_dt,
            );
            const endDate = S25Util.date.parseDropTZ(occ.event_end_dt || occ.rsrv_end_dt || occ.reservation_end_dt);
            reservations.push({
                ...occ,
                ...(!skipMerging && { neverMerge }),
                startDate,
                endDate,
            });
        }

        if (!skipMerging) {
            const exclude = [Event.Reservation.States.Exception, Event.Reservation.States.Cancelled];
            reservations = reservations.filter(
                (reservation) => !exclude.includes(Number(reservation.reservation_state)),
            );

            for (let i = reservations.length - 1; i > 0; i--) {
                if (reservations[i].neverMerge || reservations[i - 1].neverMerge) continue;
                const prevStartDate = S25Util.date.parseDropTZ(reservations[i - 1].reservation_start_dt);
                const currStartDate = S25Util.date.parseDropTZ(reservations[i].reservation_start_dt);
                if (!S25Util.date.equalDate(prevStartDate, currStartDate)) continue;

                const prevEndDate = S25Util.date.parseDropTZ(reservations[i - 1].reservation_end_dt);
                const currEndDate = S25Util.date.parseDropTZ(reservations[i].reservation_end_dt);
                const smallestDate = prevStartDate < currStartDate ? prevStartDate : currStartDate;
                const largestDate = prevEndDate > currEndDate ? prevEndDate : currEndDate;
                reservations[i - 1].reservation_start_dt = S25Util.date.toS25ISODateTimeStr(smallestDate);
                reservations[i - 1].reservation_end_dt = S25Util.date.toS25ISODateTimeStr(largestDate);
                reservations.splice(i, 1);
            }
        }

        const timeSetsHash: Record<string, TimeSet> = {};
        let timeSets: EventSummary.TimeSet[] = [];
        let mergableTimeSets = 0;
        for (let reservation of reservations) {
            const startTime = S25Util.date.toS25ISOTimeStrFromDate(reservation.startDate);
            const endTime = S25Util.date.toS25ISOTimeStrFromDate(reservation.endDate);
            const combinedTime = `${startTime}$${endTime}$${reservation.neverMerge}`;

            const timeSet = timeSetsHash[combinedTime];
            if (timeSet) {
                timeSet.endEndDate = reservation.endDate;
                timeSet.push(reservation);
            } else {
                const timeSet = [reservation] as EventSummary.TimeSet;
                timeSet.combinedTime = combinedTime;
                timeSet.neverMerge = reservation.neverMerge;
                timeSet.distStartTime = `2000-01-01T${startTime}`;
                timeSet.distEndTime = `2000-01-01T${endTime}`;
                timeSet.startTime = startTime;
                timeSet.endTime = endTime;
                timeSet.initStartDate = reservation.startDate;
                timeSet.initEndDate = reservation.endDate;
                timeSet.endEndDate = reservation.endDate;

                timeSets.push(timeSet);
                timeSetsHash[combinedTime] = timeSet;
                mergableTimeSets += reservation.neverMerge ? 0 : 1;
            }
        }

        let needsMergedCount = 0;
        for (let timeSet of timeSets) {
            const timeRatio = timeSet.length / occurrences.length;
            if (timeRatio < minTimeRatio && !timeSet.neverMerge) {
                timeSet.needsMerge = true;
                needsMergedCount++;
            }
        }
        const mergeAll = !skipMerging && needsMergedCount === mergableTimeSets;

        if (mergeAll) {
            const newTimeSets: EventSummary.TimeSet[] = [];
            let combined: EventSummary.TimeSet;

            for (let timeSet of timeSets) {
                if (timeSet.neverMerge) {
                    newTimeSets.push(timeSet);
                    continue;
                }

                if (!combined) {
                    combined = [] as EventSummary.TimeSet;
                    combined.varied = true;
                    newTimeSets.push(combined);
                }
                Array.prototype.push.apply(combined, timeSet);

                const { combinedTime, startTime, endTime, initStartDate, initEndDate } = timeSet;
                combined.combinedTime = (combined.combinedTime || "") + combinedTime + "&";
                combined.neverMerge = false;
                if (!combined.startTime || combined.startTime > startTime) combined.startTime = startTime;
                if (!combined.endTime || combined.endTime < endTime) combined.endTime = endTime;
                combined.distStartTime = `2001-01-01T${combined.startTime}`;
                combined.distEndTime = `2001-01-01T${combined.endTime}`;
                if (!combined.initStartDate || combined.initStartDate > initStartDate)
                    combined.initStartDate = initStartDate;
                if (!combined.initEndDate || combined.initEndDate < initEndDate) combined.initEndDate = initEndDate;
            }

            if (combined) combined.sort(EventSummaryService.sortRsrv);
            timeSets = newTimeSets;
        } else if (!skipMerging) {
            for (let timeSet of timeSets) {
                if (!timeSet.needsMerge || timeSet.neverMerge) continue;
                let closestTime = Number.MAX_SAFE_INTEGER;
                let closestTimeSet: EventSummary.TimeSet;

                for (let innerTimeSet of timeSets) {
                    if (innerTimeSet.needsMerge || innerTimeSet.neverMerge) continue;
                    const startTimeDistance = S25Util.date.diffMinutes(
                        timeSet.distStartTime,
                        innerTimeSet.distStartTime,
                    );
                    const endTimeDistance = S25Util.date.diffMinutes(timeSet.distEndTime, innerTimeSet.distEndTime);
                    const totalDistance = startTimeDistance ** 2 + endTimeDistance ** 2; // square root of total is not needed
                    if (totalDistance < closestTime) {
                        closestTime = totalDistance;
                        closestTimeSet = innerTimeSet;
                    }
                }

                if (!closestTimeSet) continue;

                closestTimeSet.varied = true;
                Array.prototype.push.apply(closestTimeSet, timeSet);
                closestTimeSet.sort(EventSummaryService.sortRsrv);
                timeSet.remove = true;
            }

            for (let i = timeSets.length - 1; i >= 0; i--) {
                if (timeSets[i].remove) timeSets.splice(i, 1);
            }
        }

        for (let timeSet of timeSets) {
            const monthPattern = !weeklyOnly && EventSummaryService.monthPatternMatchTimeSet(timeSet);
            if (monthPattern) {
                Object.assign(timeSet, { skips: monthPattern.skips });
                timeSet.pattern = { monthPattern: monthPattern };
            } else {
                const dowPattern = EventSummaryService.dowPatternMatchTimeSet(
                    timeSet,
                    S25Util.deepCopy(orderedDowPatterns || S25DowPatterns),
                );
                if (dowPattern && dowPattern.exceptionRatio < minExceptionRatio) {
                    Object.assign(timeSet, {
                        exceptions: dowPattern.exceptions,
                        skips: dowPattern.skips,
                        resets: dowPattern.resets,
                        pattern: { dowPattern },
                    });
                } else timeSet.pattern = null;
            }
        }

        return timeSets;
    }

    public static dowPatternMatchTimeSet(timeSet: EventSummary.TimeSet, dowPatterns: EventSummary.DowPattern[]) {
        if (S25Util.date.diffDays(timeSet[0].startDate, timeSet[timeSet.length - 1].startDate) <= 7) return null;
        let lowestExceptionRatio = Number.MAX_SAFE_INTEGER;
        let bestPattern: EventSummary.DowPattern;

        for (let pattern of dowPatterns) {
            pattern.exceptions = 0;
            pattern.skips = 0;
            pattern.resets = 0;

            let expectedDow: "*" | DowNumber = "*";
            let expectedDate: Date;
            for (let r = 0; r < timeSet.length; r++) {
                const reservation = timeSet[r];
                if (expectedDow === "*") expectedDate = reservation.startDate;

                const actualDow = reservation.startDate.getDay() as DowNumber;
                const match =
                    actualDow === expectedDow || (r === 0 && S25Util.isDefined(pattern.dowToIndex[actualDow]));
                const resetMatch = !match && (expectedDow === "*" || S25Util.isDefined(pattern.dowToIndex[actualDow]));
                const skip = match && !S25Util.date.equalDates(expectedDate, reservation.startDate);

                if (resetMatch)
                    pattern.resets++; //implies !match
                else if (!match)
                    pattern.exceptions++; //so NOT reset match but still NOT match, an exception
                else if (skip) pattern.skips++; //implies match IS true, so other branches above would not take this

                if ((pattern.exceptions + pattern.resets) / timeSet.length > lowestExceptionRatio) {
                    pattern.pruned = true;
                    break;
                }

                let nextExpectedDow: DowNumber | "*";
                if (!match && !resetMatch) nextExpectedDow = "*";
                else {
                    //remeber a skip is also a match
                    expectedDow = actualDow; //changes nothing if match; sets expected to actual if reset match, which is inline with the semantics of a reset
                    nextExpectedDow = pattern.dowArr[(pattern.dowToIndex[expectedDow] + 1) % pattern.dowArr.length];

                    if (skip) expectedDate = reservation.startDate;

                    let diff = nextExpectedDow - expectedDow;
                    if (diff <= 0) diff += (pattern.repeatsEvery || 1) * 7;

                    expectedDate = S25Util.date.addDays(expectedDate, diff);
                }

                expectedDow = nextExpectedDow;
            }

            pattern.exceptionRatio = (pattern.exceptions + pattern.resets) / timeSet.length;
            if (pattern.exceptionRatio === 0) {
                lowestExceptionRatio = 0;
                bestPattern = pattern;
                break;
            } else if (pattern.exceptionRatio < lowestExceptionRatio) {
                lowestExceptionRatio = pattern.exceptionRatio;
                bestPattern = pattern;
            }
        }

        return bestPattern;
    }

    public static monthPatternMatchTimeSet(timeSet: EventSummary.TimeSet) {
        if (S25Util.date.diffDays(timeSet[0].startDate, timeSet[timeSet.length - 1].startDate) <= 28) return null;

        const firstMonth = timeSet[0].startDate.getMonth();
        const firstYear = timeSet[0].startDate.getFullYear();
        const template = [timeSet[0].startDate.getDate()] as number[] & { skips: number };
        template.skips = 0;

        for (let { startDate } of timeSet.slice(1)) {
            if (startDate.getMonth() === firstMonth && startDate.getFullYear() === firstYear)
                template.push(startDate.getDate());
            else break;
        }

        for (let r = 0; r < timeSet.length; r++) {
            const expectedDay = template[r % template.length];
            const expectedMonth = firstMonth + (Math.floor(r / template.length) % 12);
            if (timeSet[r].startDate.getDate() !== expectedDay) return null;
            else if (timeSet[r].startDate.getMonth() !== expectedMonth) template.skips++;
        }

        return template;
    }

    public static getStartText(
        start: Date,
        end: Date,
        timeSet: EventSummary.TimeSet,
        dateFormat: DateFormat,
        timeFormat: TimeFormat,
    ) {
        if (timeSet?.[0]?.startDate) start = timeSet[0].startDate;
        if (timeSet?.[0]?.endDate) end = timeSet[0].endDate;

        const startDate = S25Datefilter.transform(start, dateFormat);
        const startTime = S25Datefilter.transform(start, timeFormat);
        const endDate = S25Datefilter.transform(end, dateFormat);
        const endTime = S25Datefilter.transform(end, timeFormat);

        let startText = "Starts on ";
        if (startDate === endDate) startText += `${startDate} ${startTime} - ${endTime}`;
        else startText += `${startDate} ${startTime} - ${endDate} ${endTime}`;

        return startText;
    }

    public static getDurationText(
        startDate: ISODateString,
        startTime: ISOTimeString,
        endDate: ISODateString,
        endTime: ISOTimeString,
        timeSet: EventSummary.TimeSet,
    ) {
        let duration: string;
        if (startDate === endDate) duration = `${startDate} ${startTime} - ${endTime}`;
        else duration = `${startDate} ${startTime} - ${endDate} ${endTime}`;

        if (!!timeSet?.varied) duration += " (with additional times)";

        return duration;
    }

    public static getWeekdayText(profileCode: string, pluralWeekdays: boolean) {
        const weekdays = S25Const.dows;
        const weekdaysFullPlural = [
            "Mondays",
            "Tuesdays",
            "Wednesdays",
            "Thursdays",
            "Fridays",
            "Saturdays",
            "Sundays",
        ];
        const weekdaysFullSingular = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        const weekdaysFull = pluralWeekdays ? weekdaysFullPlural : weekdaysFullSingular;

        const weekdayList = weekdays
            .map((day, d) => (profileCode?.includes(day) ? weekdaysFull[d] : null))
            .filter((d) => d);

        let weekdayString = weekdayList.join(", ").replace(/, ([^,]+)$/, " and $1");
        if (weekdayString === "Monday, Tuesday, Wednesday, Thursday and Friday") weekdayString = "weekdays";
        else if (weekdayString === "Saturday and Sunday") weekdayString = "weekends";
        if (weekdayString !== "") weekdayString += " ";

        return weekdayString;
    }

    public static getRepetitionText(
        profileCode: string,
        startDate: ISODateString,
        endDate: ISODateString,
        timeSet: EventSummary.TimeSet,
        weekdayString: string,
    ) {
        const type = profileCode?.match(/^[a-zA-Z]+(\d+)/) && profileCode.charAt(0);
        const exceptions = timeSet?.exceptions;
        const skips = timeSet?.skips;
        const resets = timeSet?.resets;
        const datePatternShort = "M/d";

        const exceptionText = exceptions ? " (with additional dates)" : "";
        const resetsText = resets ? "some " : "";
        const skipText = skips ? "some" : "every";

        const sDt = EventSummaryService.getDateFromProfileCode(profileCode);
        const untilDt = sDt && S25Datefilter.transform(sDt, datePatternShort);

        const repetitions = profileCode?.match(/#(\d+)/) ? profileCode.match(/#(\d+)/)[1] : null;
        const increment = profileCode?.match(/^[a-zA-Z]+(\d+)/) && parseInt(profileCode.match(/^[a-zA-Z]+(\d+)/)[1]);

        let repetitionText = "";
        if (["D", "W", "M"].includes(type) && (!timeSet || timeSet.length > EventSummaryService.OCC_LIST_THRESHOLD)) {
            repetitionText += `Repeats ${skipText} `;

            if (increment === 2) repetitionText += "other ";
            else if (increment === 3) repetitionText += "3rd ";
            else if (increment > 2) repetitionText += `${increment}th `;

            if (type === "D") repetitionText += skips ? "days " : "day ";
            else if (type === "W") {
                repetitionText += skips ? "weeks " : "week ";
                if (weekdayString.length > 0) repetitionText += `on ${resetsText}${weekdayString}`;
            } else if (type === "M") {
                repetitionText += skips ? "months " : "month ";
                const matches = profileCode.match(/\d+?\+/g);
                if (matches) {
                    const pattern = matches.map((day) => S25Util.date.suffixDay(day.replace("+", ""))).join(", ");
                    repetitionText += `on the ${pattern} `;
                }
            }

            if (untilDt) repetitionText += `through ${untilDt}${exceptionText}`;
            else if (repetitions && repetitions.trim() !== "")
                repetitionText += `for ${repetitions} iterations${exceptionText}`;
        } else if (timeSet?.length <= EventSummaryService.OCC_LIST_THRESHOLD) {
            const pattern = timeSet.map((rsrv) => S25Datefilter.transform(rsrv.startDate, datePatternShort)).join(", ");
            repetitionText = `On: ${pattern}`;
        } else if ((profileCode && startDate === endDate) || timeSet?.length <= 1 || (!profileCode && !timeSet))
            repetitionText = "";
        else repetitionText = "Ad hoc dates";

        return repetitionText;
    }

    public static getDateFromProfileCode(profileCode: string) {
        if (profileCode?.match(/\d{8}T\d{6}/)) {
            // ex: 20140623T012385-0700
            const strDate = profileCode.match(/\d{8}T\d{6}/)[0];
            const year = Number(strDate.slice(0, 4));
            const month = Number(strDate.slice(4, 6)) - 1;
            const day = Number(strDate.slice(6, 8));
            return new Date(year, month, day);
        } else if (profileCode?.match(/\d{4}-\d{2}-\d{2}T/)) {
            const strDate = profileCode.match(/\d{4}-\d{2}-\d{2}T/)[0];
            const year = Number(strDate.slice(0, 4));
            const month = Number(strDate.slice(5, 7)) - 1;
            const day = Number(strDate.slice(8, 10));
            return new Date(year, month, day);
        }
    }
}

export namespace EventSummary {
    import ISODateString = Proto.ISODateString;
    import EventReference = Proto.EventReference;
    import NumericalBoolean = Proto.NumericalBoolean;
    import ISOTimeString = Proto.ISOTimeString;
    import DowNumber = Proto.DowNumber;

    export type Summary = {
        reference: EventReference;
        loc: Location[];
        res: Resource[];
        soc: NumericalBoolean; // Standout classroom?
        event_name: string;
        event_title: string;
        state: Event.State.Id;
        prof: Profile[];
    };

    export type Location = {
        itemId: number;
        itemName: string;
        count: number;
        itemTypeId: 4;
    };

    export type Profile = {
        names: string;
        rec_type: number;
        init_start_dt: ISODateString;
        profile_id: number;
        init_end_dt: ISODateString;
    };

    export type Resource = {
        itemId: number;
        itemName: string;
        minQuantity: number;
        maxQuantity: number;
        count: number;
        itemTypeId: 6;
    };

    export type Occurrence = {
        event_start_dt?: ISODateString;
        rsrv_start_dt?: ISODateString;
        reservation_start_dt: ISODateString;

        event_end_dt?: ISODateString;
        rsrv_end_dt?: ISODateString;
        reservation_end_dt: ISODateString;

        reservation_state?: Event.Reservation.States;
    };

    export type TimeSetOccurrence = Occurrence & {
        neverMerge: boolean;
        startDate: Date;
        endDate: Date;
    };

    export type DowChars = "MO" | "TU" | "WE" | "TH" | "FR" | "SA" | "SU";
    export type DowChar = "M" | "T" | "W" | "R" | "F" | "S" | "U";

    export type DowPattern = {
        repeatsEvery?: number;
        dowString: string;
        dowArr?: DowNumber[];
        dowToIndex: Partial<Record<DowNumber, DowNumber>>;
        exceptions?: number;
        exceptionRatio?: number;
        resets?: number;
        skips?: number;
        pruned?: boolean;
    };

    export type MonthPattern = number[] & {
        skips: number;
        repeatsEvery?: number;
    };

    export type TimeSet = TimeSetOccurrence[] & {
        combinedTime: string;
        neverMerge: TimeSetOccurrence["neverMerge"];
        needsMerge?: boolean;
        distStartTime: ISODateString;
        distEndTime: ISODateString;
        startTime: ISOTimeString;
        endTime: ISOTimeString;
        initStartDate: TimeSetOccurrence["startDate"];
        initEndDate: TimeSetOccurrence["endDate"];
        exceptions?: number;
        resets?: number;
        varied?: boolean;
        remove?: boolean;
        skips?: ReturnType<typeof EventSummaryService.monthPatternMatchTimeSet>["skips"];
        endEndDate?: TimeSetOccurrence["endDate"];
        pattern?: {
            dowPattern?: DowPattern;
            monthPattern?: MonthPattern;
        };
    };
}
