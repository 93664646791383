import { SwarmSchedule } from "./SwarmSchedule";
import { BoardService } from "./s25.board.service";
import { S25Util } from "../../util/s25-util";
import { BOARD_CONST } from "./s25.board.const";
import { Proto } from "../../pojo/Proto";
import { EventSummary, EventSummaryService } from "./s25.event.summary.service";
import { ValueOf } from "../../pojo/Util";
import { BoardUtil } from "./s25.board.util";
import ISODateString = Proto.ISODateString;
import BoardOrganization = SwarmSchedule.BoardOrganization;
import BoardEvent = SwarmSchedule.BoardEvent;
import BoardRoom = SwarmSchedule.BoardRoom;
import DowNumber = Proto.DowNumber;
import { Cache } from "../../decorators/cache.decorator";

export type OptimizerBoard = {
    allowXDrag: boolean;
    item: BoardEvent[];
    room: BoardRoom[];
    minFillRatio: number;
    origResults: { results: SwarmSchedule.OptimizerData["results"] };
    origResultsMap: Record<string, SwarmSchedule.OptimizerResult>;
    last_timestamp: SwarmSchedule.BoardRespI["last_timestamp"];
} & SwarmSchedule.BoardI;

export class BoardUploadService {
    public static getPartialItemMap(events: SwarmSchedule.EventWSI[]) {
        events = events ? S25Util.array.forceArray(events) : [];
        return S25Util.fromEntries(events.map((e) => [e.uuid.split("-").slice(0, 2).join("-"), e]));
    }

    public static getPartitionMap(partitions: SwarmSchedule.OptimizerPartitions["partition"]) {
        partitions = partitions ? S25Util.array.forceArray(partitions) : [];
        return S25Util.fromEntries(
            partitions
                .filter((p) => Number(p.id))
                .map((p) => [Number(p.id), { partitionId: Number(p.id), partitionName: p.name }]),
        );
    }

    public static getFeatureMap(features: SwarmSchedule.OptimizerFeatures["feature"]) {
        features = features ? S25Util.array.forceArray(features) : [];
        return S25Util.fromEntries(
            features
                .filter((f) => Number(f.id))
                .map((f) => [Number(f.id), { featureId: Number(f.id), featureName: f.name }]),
        );
    }

    public static getOrganizationMap(
        organizations: SwarmSchedule.OptimizerOrganizations["organization"],
        partitionMap: ReturnType<typeof BoardUploadService.getPartitionMap>,
    ): Record<number, BoardOrganization> {
        organizations = organizations ? S25Util.array.forceArray(organizations) : [];
        return S25Util.fromEntries(
            organizations
                .filter((o) => Number(o.id))
                .map((o) => [
                    Number(o.id),
                    {
                        organizationId: Number(o.id),
                        organizationName: o.name,
                        partitions: S25Util.array.forceArray(o.partitions || []).map((p) => ({
                            priority: Number(p.priority),
                            list: S25Util.array
                                .forceArray(p?.partition)
                                .map((p) => partitionMap[BoardUploadService.getNodeId(p)])
                                .filter((p) => !!p),
                        })),
                    },
                ]),
        );
    }

    public static getSpaceMap(
        spaces: SwarmSchedule.OptimizerSpaces["space"],
        partitionMap: ReturnType<typeof BoardUploadService.getPartitionMap>,
        featureMap: ReturnType<typeof BoardUploadService.getFeatureMap>,
    ): Record<number, BoardRoom> {
        spaces = spaces ? S25Util.array.forceArray(spaces) : [];
        return S25Util.fromEntries(
            spaces
                .filter((space) => Number(space?.id))
                .map((space) => [
                    Number(space.id),
                    {
                        roomId: Number(space.id),
                        roomName: space.name || undefined,
                        maxCapacity: Number(space.capacity) || undefined,
                        partition: space.partition && partitionMap[Number(space.partition)],
                        features: S25Util.array
                            .forceArray(space.features?.feature)
                            .map((f) => featureMap[BoardUploadService.getNodeId(f)])
                            .filter((p) => !!p),
                        blackoutDates: S25Util.array.forceArray(space.blackout?.dates),
                    },
                ]),
        );
    }

    public static getResultMap(results: SwarmSchedule.OptimizerResults["result"]) {
        results = results ? S25Util.array.forceArray(results) : [];
        const map: Record<string, SwarmSchedule.OptimizerResult> = {};
        for (let r of results?.filter((r) => r.key)) {
            map[r.key] = r;
            if (r.key.startsWith("BND-")) {
                //eg: BND-40199 39287-40197 39288-40199
                for (let k of r.key.split(" ").slice(1)) {
                    map[k] = r;
                }
            }
        }
        return map;
    }

    public static isEventGroup(event: SwarmSchedule.OptimizerEvent) {
        return event.type !== undefined;
    }

    public static isEventUnassigned(event: SwarmSchedule.OptimizerEvent) {
        return !BoardUploadService.isEventGroup(event) && !BoardUploadService.isEventAssigned(event);
    }

    public static isEventAssigned(event: SwarmSchedule.OptimizerEvent) {
        return event.assigned !== undefined;
    }

    public static getNodeId(node: any) {
        if (typeof node === "string") return Number(node);
        if (typeof node === "number") return node;
        return Number(node.text);
    }

    @Cache({ targetName: "BoardUploadService" })
    public static async createBoardFromOptimizer(board: SwarmSchedule.BoardI) {
        const [events, spaces, results, control, features, partitions, organizations, boardModel] = await Promise.all([
            BoardService.getOptimizerFile(board.boardId, "events").then((data) => data?.events),
            BoardService.getOptimizerFile(board.boardId, "spaces").then((data) => data?.spaces),
            BoardService.getOptimizerFile(board.boardId, "results").then((data) => data?.results),
            BoardService.getOptimizerFile(board.boardId, "control").then((data) => data?.control),
            BoardService.getOptimizerFile(board.boardId, "features").then((data) => data?.features),
            BoardService.getOptimizerFile(board.boardId, "partitions").then((data) => data?.partitions),
            BoardService.getOptimizerFile(board.boardId, "organizations").then((data) => data?.organizations),
            BoardService.getBoard(board.boardId, board.boardUUID),
        ]);
        const allowXDrag = boardModel.allowXDrag;
        const minFillRatio = parseFloat(control.minimum_fill_ratio) || 0;

        const boardItemPartialMap = BoardUploadService.getPartialItemMap(boardModel?.board?.event);
        const partitionMap = BoardUploadService.getPartitionMap(partitions?.partition);
        const featureMap = BoardUploadService.getFeatureMap(features?.feature);
        const organizationMap = BoardUploadService.getOrganizationMap(organizations?.organization, partitionMap);
        const spaceMap = BoardUploadService.getSpaceMap(spaces?.space, partitionMap, featureMap);
        const unassignedRoom = { roomId: -1, roomName: "UNASSIGNED" };
        spaceMap[-1] = unassignedRoom as ValueOf<typeof spaceMap>;

        const resultMap = BoardUploadService.getResultMap(results?.result || []);

        let occProcessing = 0;
        let timeSetsProcessing = 0;
        let syntheticCount = 0;
        let naturalCount = 0;
        let eventRoomCount: Record<string, number[]> = {};

        const items: BoardEvent[] = [];
        for (let event of events.event) {
            if (BoardUploadService.isEventGroup(event)) continue; // Exclude groups

            const features = [];
            if (BoardUploadService.isEventUnassigned(event)) {
                for (let r of S25Util.array.forceArray(event.required)) {
                    for (let f of S25Util.array.forceArray(r.feature)) {
                        features.push(featureMap[BoardUploadService.getNodeId(f)]);
                    }
                }
            }

            const [eventId, profileId] = String(event.key).split("-");
            if (!eventId) continue;

            let now = performance.now();
            const occs: { reservation_start_dt: ISODateString; reservation_end_dt: ISODateString }[] = [];

            if (event.dates?.start) {
                const start = S25Util.date.parseDropTZ(event.dates?.start);
                const end = S25Util.date.parseDropTZ(event.dates?.end);
                occs.push({
                    reservation_start_dt: S25Util.date.dropTZFromISODateTimeString(event.dates.start),
                    reservation_end_dt: S25Util.date.dropTZFromISODateTimeString(event.dates.end),
                });

                for (let d of S25Util.array.forceArray(event.dates.day)) {
                    const day = S25Util.date.parseDropTZ(typeof d === "string" ? d : d.text);
                    if (!day) continue;

                    occs.push({
                        reservation_start_dt: S25Util.date.dropTZString(S25Util.date.syncDateToTime(day, start)),
                        reservation_end_dt: S25Util.date.dropTZString(S25Util.date.syncDateToTime(day, end)),
                    });
                }
            } else if (event.occurrences?.occurrence) {
                for (let occ of S25Util.array.forceArray(event.occurrences.occurrence)) {
                    occs.push({
                        reservation_start_dt: S25Util.date.dropTZFromISODateTimeString(occ.start),
                        reservation_end_dt: S25Util.date.dropTZFromISODateTimeString(occ.end),
                    });
                }
            }

            const groupId = BoardUploadService.isEventUnassigned(event) && event.groupid;

            occProcessing += performance.now() - now;
            now = performance.now();

            const orderedDowPatterns = BoardUtil.getDowPatterns(occs);

            const timeSets = EventSummaryService._adhocSummaryModel(occs, -1, 99_999, orderedDowPatterns, true, true);
            timeSetsProcessing += performance.now() - now;

            const timeSet = timeSets[0];
            let profileCode: string;
            let dow: string;
            let startTime: EventSummary.TimeSet["startTime"];
            let endTime: EventSummary.TimeSet["endTime"];
            let syntheticProfile = false;
            if (timeSet?.pattern) {
                naturalCount++;
                profileCode = EventSummaryService.adhocSummaryModelToProfileCode(timeSet);
                const hasDow = (dow: EventSummary.DowChars) => (profileCode.includes(dow) ? dow : null);
                dow =
                    [hasDow("MO"), hasDow("TU"), hasDow("WE"), hasDow("TH"), hasDow("FR"), hasDow("SA"), hasDow("SU")]
                        .filter((a) => a)
                        .join(" ") + " ";
                startTime = timeSet.startTime;
                endTime = timeSet.endTime;
            } else if (occs.length) {
                syntheticCount++;
                let dows = occs.map((occ) => S25Util.date.parseDropTZ(occ.reservation_start_dt).getDay() as DowNumber);
                dows = S25Util.array.unique(dows).sort();
                const dowsAbbr = dows.map((dow) => BOARD_CONST.dowInt2Abbr[dow]);
                syntheticProfile = true;
                const endDate = S25Util.date.toS25ISODateTimeStrNoPuncEndOfDay(
                    occs[occs.length - 1].reservation_end_dt,
                );
                profileCode = `W1 ${dowsAbbr.join(" ")} ${endDate}`;
                dow = dowsAbbr.join(" ") + " ";
                startTime = S25Util.date.toS25ISOTimeStr(occs[0].reservation_start_dt);
                endTime = S25Util.date.toS25ISOTimeStr(occs[0].reservation_end_dt);
            }

            if (!occs.length) continue;

            const result = resultMap[event.key];
            const outcome = result?.outcome;
            const room = spaceMap[Number(event.room) || Number(result?.space)];

            const item: BoardEvent = {
                origEvent: event,
                eventName: event.title || event.name || result.name,
                boardUUID: board.boardUUID,
                eventId: eventId,
                moved: result?.moved,
                newpattern: event.newpattern,
                profileId: profileId,
                syntheticProfile,
                profileCode,
                origDow: dow,
                dow,
                origStartTime: startTime,
                startTime,
                endTime,
                occs,
                room: room || unassignedRoom,
                features,
                groupId: groupId,
                linkedItems: [] as BoardEvent[], // Bound items
                enrollment: Number(event.enrollment) || 0,
                organization: event.organization && organizationMap[Number(event.organization)],
                draggable: !syntheticProfile && ["notplaced", "impossible", "placed"].includes(outcome),
                multipleRooms: false,
            };

            const partialItemUUID = BoardUtil.partialItemHash(item);
            if (room?.roomId) {
                eventRoomCount[partialItemUUID] = eventRoomCount[partialItemUUID] || [];
                eventRoomCount[partialItemUUID].push(room.roomId);
            }
            const boardItem = boardItemPartialMap[partialItemUUID];
            if (boardItem) {
                item.uuid = boardItem.uuid;
                item.profileCode = boardItem.profile_code;
                if (allowXDrag) {
                    item.dow = boardItem.dow;
                    item.startTime = boardItem.start_time;
                    item.endTime = boardItem.end_time;
                }
                item.room = spaceMap[Number(boardItem.room_id)];
                item.ts = boardItem.item_timestamp;
            }

            item.startHour = S25Util.date.timeToHours(item.startTime);
            item.endHour = S25Util.date.timeToHours(item.endTime);

            items.push(item);
        }

        const groupHash: Record<string, BoardEvent[]> = {};
        for (let item of items) {
            const partialItemUUID = BoardUtil.partialItemHash(item);
            if (S25Util.array.unique(eventRoomCount[partialItemUUID] || [])?.length > 1) {
                // Multi room events cannot be dragged
                item.multipleRooms = true;
                item.draggable = false;
            }

            if (item.groupId) {
                if (!groupHash[item.groupId]) groupHash[item.groupId] = [];
                groupHash[item.groupId].push(item);
                item.linkedItems = groupHash[item.groupId];
            }
        }

        return {
            root: {
                ...board,
                allowXDrag,
                item: items,
                room: Object.values(spaceMap),
                minFillRatio,
                origResults: { results },
                origResultsMap: resultMap,
                last_timestamp: boardModel?.board?.last_timestamp,
            } as OptimizerBoard,
        };
    }

    public static getEventId(key: string) {
        return key && String(key).split("-")?.[0];
    }

    public static getProfileId(key: string) {
        return key && String(key).split("-")?.[1];
    }
}
